import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Icon from "../components/ui/Icon"
import ContactForm from "../components/contact-form"
import Image from "../components/ui/Image"
import Content from "../components/content"

const AboutPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark
  const {
    firstName,
    image,
    tagLine,
    blockquote,
    bioImage,
    technologies,
  } = frontmatter
  return (
    <Layout>
      <SEO
        title="About Me"
        description="All about me, Randall Lough, a burrito eating enthusiast and software engineer"
        path="/about"
        keywords={[
          `about randall lough`,
          `marketer turned software engineer`,
          "programmer",
          "coder",
          "software engineer",
          "web developer",
        ]}
      />
      <section className="mb-16">
        <div className="max-w-4xl px-5 mx-auto">
          <div className="pb-16 md:flex md:space-x-16 md:text-left text-center">
            <figure className="md:mx-0 mx-auto w-4/12 self-center">
              <Image {...image} alt="my profile image" />
              <figcaption className="text-center text-gray-500 text-sm font-bold py-3">
                <span role="img" aria-label="emoji finger pointing up">
                  👆
                </span>{" "}
                Hey that's me!
              </figcaption>
            </figure>
            <header>
              <h1 className="font-bold font-serif text-5xl md:text-6xl text-gray-600">
                <span className="font-black inline-block mr-3 text-primary-500">
                  Hi!
                </span>
                I'm {firstName},
              </h1>
              <h3 className="mb-5 text-3xl md:text-4xl text-orange-500 leading-tight">
                {tagLine}
              </h3>
              <Link
                className="border border-secondary-500 hover:border-transparent bg-transparent hover:bg-secondary-400 rounded px-4 py-2 text-secondary-500 hover:text-white inline-block transition transform duration-200 hover:-translate-y-px hover:shadow-md"
                to="/resume"
              >
                <span className="mr-2">View Resume</span>
                <Icon icon="arrow-right" />
              </Link>
            </header>
          </div>
          <div className="md:flex md:space-x-16">
            <div className="text-xl text-gray-700 md:w-1/2 mb-16">
              <h2 className="font-bold text-gray-700 text-2xl mb-2">Bio</h2>
              <Content>{html}</Content>
            </div>
            <div className="flex flex-1 flex-col justify-around">
              {blockquote && (
                <blockquote className="border-4 px-10 py-10 rounded-lg relative">
                  <span
                    className="-mt-8 absolute bg-white p-3 rounded-full shadow text-orange-500 top-0"
                    style={{ left: "50%", transform: "translateX(-50%)" }}
                  >
                    <Icon
                      icon="quote-right"
                      size="2x"
                      className="fill-current"
                    />
                  </span>

                  <p className="font-black mb-2 text-2xl text-primary-500">
                    {blockquote.quote}
                  </p>
                  {blockquote.source && (
                    <footer className="text-gray-600 text-lg">
                      —{blockquote.source}
                    </footer>
                  )}
                </blockquote>
              )}
              {bioImage && (
                <figure className="mb-10 hidden md:block">
                  <Image
                    {...bioImage}
                    alt="ollie and randy lough in yosemite"
                    className="rounded rounded-lg shadow-2xl"
                  />
                  <figcaption className="text-center text-gray-500 text-sm font-bold py-3">
                    Ollie and me in Yosemite
                  </figcaption>
                </figure>
              )}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="border-b border-t flex flex-wrap justify-center lg:px-10 mx-auto my-24 py-5 text-center w-full lg:w-3/4">
          <div className="-mt-10 bg-white mb-3 mb-8 px-6">
            <h4 className="font-bold text-2xl text-secondary-500">
              Technologies
            </h4>
          </div>
          <p className="mb-6 text-gray-600 text-xl w-full">
            {technologies.map(({ name }) => name).join(", ")}
          </p>
        </div>
      </section>
      <section className="bg-gray-100 py-24 border-t border-b">
        <div className="max-w-4xl px-5 mx-auto">
          <div className="flex justify-between">
            <div>
              <h4 className="text-secondary-500 text-4xl font-display font-bold">
                What to know more?
              </h4>
              <p className="text-gray-600 text-xl">
                I have plenty for you too look at.
              </p>
            </div>
            <div className="flex items-center self-center space-x-4">
              <Link
                className="border border-gray-500 bg-transparent hover:bg-secondary-400 hover:border-transparent rounded px-4 py-2 text-gray-500 hover:text-white inline-block transition transform duration-200 hover:-translate-y-px hover:shadow-md"
                to="/projects"
              >
                View Projects
              </Link>
              <Link
                className="border border-transparent bg-secondary-500 hover:bg-secondary-400 rounded px-4 py-2 text-white inline-block transition transform duration-200 hover:-translate-y-px hover:shadow-md"
                to="/resume"
              >
                View Resume
              </Link>
            </div>
          </div>
        </div>
        <div className="overflow-x-scroll"></div>
      </section>
      <section className="bg-gray-800 py-24">
        <div className="max-w-4xl px-5 mx-auto">
          <header className="text-center mb-10">
            <h2 className="text-white text-4xl">Contact me</h2>
          </header>
          <div className="md:w-3/4 mx-auto">
            <div className="bg-gray-100 px-10 py-8 shadow-md w-full rounded">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($author: String!) {
    markdownRemark(frontmatter: { personId: { eq: $author } }) {
      html
      fields {
        slug
      }
      frontmatter {
        name
        firstName
        tagLine
        github
        linkedin
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blockquote {
          quote
          source
        }
        bioImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        technologies {
          name
        }
      }
    }
  }
`

export default AboutPage
