import React, { useState } from "react"
import { navigate } from "gatsby-link"
import { encode } from "../util/form"

const ContactForm = props => {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="mb-4">
        <label
          htmlFor="input-email"
          className="block text-gray-700 text-sm font-bold"
        >
          Email Address
        </label>
        <input
          id="input-email"
          name="email"
          className="shadow-sm appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight text-base focus:outline-none focus:border-primary-300 focus:shadow"
          placeholder="Enter your email"
          required=""
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="input-name"
          className="block text-gray-700 text-sm font-bold"
        >
          Name
        </label>
        <input
          id="input-name"
          name="name"
          className="shadow-sm appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight text-base focus:outline-none focus:border-primary-300 focus:shadow"
          placeholder="What's your name?"
          type="text"
          required=""
          onChange={handleChange}
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="input-message"
          className="block text-gray-700 text-sm font-bold"
        >
          Message
        </label>
        <textarea
          name="message"
          id="input-message"
          cols="30"
          rows="10"
          className="shadow-sm appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight text-base focus:outline-none focus:border-primary-300 focus:shadow resize-y"
          placeholder="What can I do for ya?"
          onChange={handleChange}
        />
      </div>
      <button
        className="font-bold outline-none focus:outline-none transition-all duration-150 text-sm px-6 py-3 rounded shadow hover:shadow-md border border-transparent text-white bg-primary-500 hover:bg-primary-400 focus:bg-primary-600 transform hover:-translate-y-px w-full"
        type="submit"
      >
        Submit
      </button>
    </form>
  )
}

export default ContactForm
