import React from "react"

const Content = props => {
  const { children, ...rest } = props
  return (
    <div
      className="rich-content"
      dangerouslySetInnerHTML={{ __html: children }}
      {...rest}
    ></div>
  )
}

export default Content
